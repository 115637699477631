<div class="main-menu-component">
  <a
    *ngFor="let item of menuItems"
    class="main-menu-component__link-item"
    [routerLink]="[item.href]"
    [queryParams]="item.queryParams"
  >
    {{ item.name }}
  </a>
</div>
