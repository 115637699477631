export function platformChatExample() {
  const element = document.querySelector('#example-wrapper__right-panel-content');
  while (element.firstElementChild) {
    element.firstElementChild.remove();
  }

  //Сделаем все вокрун чата примерно как в макете РПН
  const innerElement = document.createElement('div');
  innerElement.style.setProperty('height', '100%');
  innerElement.style.setProperty('background-color', '#E3E8EF');
  innerElement.style.setProperty('padding', '16px');
  innerElement.style.setProperty('box-sizing', 'border-box');
  element.appendChild(innerElement);

  //Инициализация чата
  const chat = new window['b3-chat']({
    //Сообщение, которые будет отправлено после инициализации чата
    //initialRequestMessage: 'Привет! Что ты умеешь?',

    //Таймаут для запроса истории в миллисекундах, по умолчанию 5000
    //refreshHistoryTimeout: 6000,
    
    
    //Авторизация
    jwt: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImVzaWFfaWQiOiJzYSIsInVzZXJuYW1lIjoic2EiLCJmaXJzdF9uYW1lIjoiXHUwNDIxXHUwNDM1XHUwNDQwXHUwNDMzXHUwNDM1XHUwNDM5IiwibGFzdF9uYW1lIjoiXHUwNDFjXHUwNDNlXHUwNDM4XHUwNDQxXHUwNDM1XHUwNDM1XHUwNDMyIiwiZW1haWwiOiJzYUBiaWczLnJ1In0sInJvbGVzIjpbImNhcnJpZXJfY29tcGFueV9hZG1pbiIsImNhcnJpZXJfY29tcGFueSIsImFkbWluIiwiY2xhdGkiLCJyZWdfb3BlcmF0b3JfYWRtaW4iXSwib3JnIjp7ImVzaWFfaWQiOiIiLCJpbm4iOiI3NzE2NjY4MDQ5Iiwia3BwIjoiNzczMTAxMDAxIiwib2dybiI6IjExMDc3NDY1NzQzMDgiLCJzaG9ydF9uYW1lIjoiXHUwNDFlXHUwNDFlXHUwNDFlIFwiXHUwNDExXHUwNDFlXHUwNDFiXHUwNDJjXHUwNDI4XHUwNDEwXHUwNDJmIFx1MDQyMlx1MDQyMFx1MDQxZVx1MDQxOVx1MDQxYVx1MDQxMFwiIn0sInN1YiI6InNhIiwiaWF0IjoxNjk1MDQzODE1LCJleHAiOjQ4NDg2NDM4MTYuNTEwMjc5LCJqdGkiOiIzOTFiYmNmZS1kNGQ4LTQ3ZDEtOWFlOC1mMDQ0NGNhYTE5ZjciLCJpc3MiOiJ1b2l0In0.ojFNBFnM3a1Le5uzdBJcrUVQ1xSdiyjAcuXG50rmi3V3c-GepF1IQIi0El8i_pl-Wh8GwS0SMolEytl7c0-WKCrY-I8ucZyrRWIvQQy_NAnnKxa7PVrQeV5imIixQZWkQ8ll50TtzkxlTHm0c9jADLSRLud77abCnN2NAu243CrhR7I94WzUwzc08a3IHrKeCcGjb2hiPq80rngB6JzJMtHKBW8AwQc6-G-JWVrRP2BQ3emhZvLTKgSh02zjjPMPl3ONFJmG6eYnfZo8xQWtvih4Bxdr-PTX3kv09noYrMG3Qt64KiR03_m9ZE-VVXXAfsIVk0-QMnYigXgmxcJvsRx5mkpgWpx3Vr5DuakxGc5X0QHaKu6Nx3ZteesLFNz0qTvwtqjTMM_w4P7fCcyV_JftqwI_x5tH5jhQsqfp1QQuN3u4FvwqTha6kuWyBcGuur4yAFMF1S6Eu7rVMSuEiuDEw7_T28XYQFEkzzVCjY2i0XepyM0b6W6xl7grSnjZpwGf2SgP1TsiRgVl-dYvlj6N6-yviAO1kaC7jlKzc4MxPkoKlyLFuHtb3UZRkbAZC-JZ-0GCmrjynV02Toy8QZBaAL__lU_k5rdzR2nG5fCAXoT0GWt7U5ShiKzhzZ0ZgueCRrcgvzU941HPpduj88rCJq9ozd188GQKma7vSf4',

    //Бэкэнд
    backendUrl: 'https://mls.big3.ru/',
    pipeline: 'anatoliy',
    backendPath: '/api/v1/chat/history/',
    options: {
      manager_id: 4,
      host: 'lk.develop.v2.rpn.big3.ru'
    },

    //История сообщений
    loadHistory: true,
    messageLoadCount: 5,

    //Быстрые действия, пока не поддерживается
    /*
    hintConfig: {
      path: '/api/v1/recognize/datasets/',
      pipeline: 'quick_actions_temp',
      hintsCount: 5
    },
    */

    //Стилизация
    cssClass: 'platform-chat',
    imagesUrl: 'https://205129.selcdn.ru/b3-gis/lib-chat-platform/images/',

    //Колбэк на события чата
    onEvent: (eventType, eventData) => {
      window.console.log(eventType, eventData);
    }
  });

  //Добавление чата в разметку
  innerElement.appendChild(chat.getDOM());
}
